import { urlRs } from "~/utils/url";
import { useEventsContext } from "~/contexts/EventsContext";
import imgProxy from "~/utils/imgproxy";

import "./FrontPlot.css";

export default function FrontPlot() {
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section class="front-plot">
        <div class="inner" data-test="plot">
          <picture class="visual">
            <img
              src={imgProxy(
                "/images/front-page/front-cogedim-et-vous-visual-03.jpg",
                `size:400:400/resizing_type:fill`,
              )}
              alt=""
              height="200"
              width="200"
              loading="lazy"
            />
          </picture>
          <div class="text-part">
            <h2>
              <span>Vous avez</span> <strong>un terrain à vendre</strong>&nbsp;?
            </h2>
            <p class="catchline">
              Recevez une estimation d'achat de votre bien rapide, en direct et
              au meilleur prix.
            </p>
          </div>
          <a
            href={urlRs("plot", "/vendre-mon-terrain/")}
            onClick={() => {
              sendClick("btn-plot", "plot");
            }}
            class="btn link-button"
          >
            J’estime mon bien
          </a>
        </div>
      </section>
    </>
  );
}
