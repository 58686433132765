import { urlRs } from "~/utils/url";

import "./FrontPromoEscda.css";

export default function FrontPromoEscda() {
  return (
    <>
      <section class="front-promo-escda" data-test="escda-home">
        <div class="block">
          <div class="inner">
            <img
              src="/images/logo-escda-2025.svg"
              width="150"
              height="160"
              alt="Élu Service Client de l'Année 2025 dans la catégorie Promotion immobilière"
            />
            <div class="text-part">
              <p>Pour la 7ème fois</p>
              <h2>
                Cogedim Élu Service Client de l'Année* dans la catégorie
                Promotion immobilière.
              </h2>
              <a
                href={urlRs(
                  "journeys",
                  "/cogedim-pour-vous/pourquoi-choisir-cogedim/un-accompagnement-sur-mesure.html",
                )}
                class="btn"
              >
                En savoir plus
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
