import ProgramsNearbyList from "~/components/Program/Components/ProgramsNearbyList";

import type { ItemReference } from "~/types/drupal_jsonapi";

import "./FrontPromotedPrograms.css";
import { ProjectErrorBoundary } from "~/components/shared/ProjectErrorBoundary";

export default function FrontPromotedPrograms(props: {
  programs: ItemReference[];
}) {
  return (
    <>
      <section class="front-promoted-programs" data-test="programs-promoted">
        <h2 class="h2">
          <strong>Résidences</strong> à la Une
        </h2>
        <div class="programs-list" data-ga-zone="list">
          <ProjectErrorBoundary>
            <ProgramsNearbyList nearbyPrograms={props.programs} />
          </ProjectErrorBoundary>
        </div>
      </section>
    </>
  );
}
